<template>
  <div class="contact">
    <div class="message-logo d-flex justify-content-center">
      <div>
        <div class="box-right">COMMENT PEUT-ON VOUS AIDER?</div>
      </div>
      <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
    </div>
    <div class="d-flex flex-column gap-4 align-items-center">
      <input placeholder="Votre e-mail..." class="mail-input" type="text" />
      <textarea
        placeholder="votre message..."
        class="contact-textarea"
        id=""
        cols="30"
        rows="10"
      ></textarea>
      <p>
        Ou écrivez à notre email
        <a href="mailto:contact@edays.io">contact@edays.io</a>
      </p>

      <button class="main-button">Envoyer</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  metaInfo() {
    return {
      title:
        "Edays - Agir contre déforestation - comment aider l’environnement",
      meta: [
        {
          name: "title",
          content:
            "Edays - Agir contre déforestation - comment aider l’environnement",
        },
        {
          name: "description",
          content:
            "Edays participe au reboisement des forêts sur toute la planète en plantant des arbres",
        },
        {
          property: "og:title",
          content:
            "Edays - Agir contre déforestation - comment aider l’environnement",
        },
      ],
    };
  },
};
</script>
